import React from 'react';
import Layout from '../../components/layout';
import Questions from '../../components/Questions';

const AkneUndAknenarben = ({ data, location, ...props }) => {
	return (
		<Layout
			location={location}
			title="Akne und Aknenarben"
			noSlider
			{...props}
		>
			<p>
				Die in der Pubertät, aber auch in späteren Jahren als Spätakne,
				auftretenden unschönen Hautveränderungen mit Unreinheiten,
				Pickeln, Abszessen, Narben können in verschiedenen
				Ausprägungsformen in Erscheinung treten.
			</p>
			<p>
				Problematisch ist die entzündliche Form der Hautveränderungen
				mit den beliebten Pickelchen und Pickeln. Diese können unsere
				Haut in unterschiedlicher Tiefe und Schweregrad befallen und zu
				Vernarbungen führen.
			</p>
			<p>
				Durch eine sachgerechte und effiziente Therapie werden wir dies
				unter allen Umständen vermeiden können.
			</p>

			<p>
				Neben der medizinischen Behandlung Ihrer Akne bieten wir
				ergänzende Behandlungen an mit denen sowohl der Ausprägungsgrad
				der Akne verringert wird als auch narbige Veränderungen deutlich
				verbessert werden. Hierzu zählen die phantastischen
				medizinischen Peelings mit Salicylsäure , Jessner Lösung oder
				auch Trichloressigsäure Peelings in vielfältigen
				Konzentrationen, abhängig vom gewünschten Effekt. Aber auch
				Laser, Mikroneedling mit oder ohne Plasmatherapie oder auch
				Radiofrequenztechnik.
			</p>
			<p>
				Sie selbst können durch eine ausgewogene Ernährung mit wenig
				Milchprodukten, wenig Zucker, wenig Fett, kurzum durch eine
				gesunde Lebensweise zu einem besseren Verlauf Ihrer Akne
				beitragen.
			</p>
			<p>
				Wir empfehlen Ihnen selektiv angepasste Pflegeprodukte und
				begleiten Sie mit unserem Fachwissen bis es Ihnen wieder gut
				geht.
			</p>
			<Questions title="Akne und Aknenarben" prefix="um" />
		</Layout>
	);
};

export default AkneUndAknenarben;
